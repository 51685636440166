import * as React from "react"
import { Link, graphql } from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image"

import "../styles/main.scss"
import Layout from "../layouts/layout"

export default class Index extends React.Component {

    props: {
        data: any;
    }

    constructor(props) {
        super(props)
    }

    public render() {

        return (
            <Layout>
                
                <div className={"container left-split-layout"}>
                    <GatsbyImage className={"centered"} image={this.props.data.json.file.childImageSharp.gatsbyImageData} alt="A dinosaur"/>
                    <div>
                        <h3>{this.props.data.json.title}</h3>
                        <p>{this.props.data.json.description}</p>
                    </div>
                </div>
            </Layout>
            
        )
    }
}


export const pageQuery = graphql`
    query ImageQuery ($id: String!) {
        json ( id: { eq: $id } ) {
            file {
                childImageSharp {
                    gatsbyImageData(height: 700, layout: CONSTRAINED)
                }
            }
            description
            title
            id
        }
    }
`